import { BFormSelect } from 'bootstrap-vue'
export default {
    components: { BFormSelect },
    props: {
        modalShow: {
            default: false,
        },
        document_types: {
            default: [],
        },
    },
    data() {
        return {
            displayModal: false,
            files: [],
            fileError: null,
            acceptedMimeType: ['image/jpeg', 'image/png'],
            documentType: '',
            validDocumentType: true,
            successText: '',
            uploaded: false,
        }
    },
    mounted() {
        this.displayModal = this.modalShow
        this.uploaded = false
    },
    methods: {
        hideModal() {
            this.displayModal = false
        },
        contactGS() {
            this.$http.post('/send-help-request-file-upload', {
                
            })
            .then(response => {
                if (response.status == 200) {
                    this.$helper.showMessage(response.data.message);
                    this.hideModal();
                }

            }).catch((e) => {
                console.log(e);
            })
        },
        openFileSelector(id) {
            document.getElementById(id).click()
            this.fileError = null
        },
        setFile() {
            this.files = [
                ...this.$refs.files.files,
                ...this.$refs.files_2.files,
            ]
        },
        validate() {
            if (
                this.documentType === 'nz_drivers_license' &&
                this.files.length < 2
            ) {
                this.fileError = 'Please choose both files'
                return false
            } else if (
                this.documentType !== 'nz_drivers_license' &&
                this.files.length < 1
            ) {
                this.fileError = 'Please choose a file'
                return false
            }
            for (let i = 0; i < this.files.length; i++) {
                if (this.acceptedMimeType.indexOf(this.files[i].type) < 0) {
                    this.fileError = 'Please choose JPEG/PNG Files'
                    return false
                }
            }
            return true
        },
        async upload() {
            if (this.documentType === '') {
                this.validDocumentType = false
                return
            }
            if (this.documentType !== 'nz_drivers_license') {
                this.files = this.files.slice(0, 1)
            }
            const validation = this.validate()
            if (validation) {
                let formData = new FormData()
                formData.append('document_type', this.documentType)
                formData.append
                for (let i = 0; i < this.files.length; i++) {
                    formData.append('id_proofs[' + i + ']', this.files[i])
                }
                try {
                    const response = await this.$http.post(
                        '/upload-id',
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        }
                    )
                    if (response.data.status) {
                        this.successText = response.data.message
                        this.uploaded = true
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        },
        finish() {
            this.$emit('uploaded')
            this.hideModal()
        },
    },
    computed: {
        activeUser() {
            return this.$store.getters['auth/getAuthenticatedUser']
        },
        firstFileName() {
            return this.files.length > 0
                ? this.files[0].name
                : this.documentType === 'nz_drivers_license'
                ? 'Upload front side'
                : 'Upload your image'
        },
        secondFileName() {
            return this.files.length > 1
                ? this.files[1].name
                : 'Upload back side'
        },
    },
}
