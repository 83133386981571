import verificationModal from '@/components/verification_modal/'
import idVerificationModal from '@/components/id_verification_modal/'
import { Carousel, Slide } from 'vue-carousel'
import moment from 'moment'

export default {
    name: 'home',
    components: {
        Carousel,
        Slide,
        verificationModal,
        idVerificationModal,
    },
    data() {
        return {
            modalShow: false,
            user_data: null,
            show_verification: false,
            stateLoaded: false,
            response: null,
            groupData: {},
            isLoading: true,
            whats_up_datas: [],
            member_status_based_text: '',
            document_types: [],
        }
    },
    created() {
        if (localStorage.getItem('token')) {
            this.$store.dispatch('auth/attempt', localStorage.getItem('token'))
        }
        setTimeout(() => {
            this.stateLoaded = true
            this.getData()
        }, 2000)
    },
    mounted() {
        this.$http
            .get('/config')
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.whats_up.length) {
                        this.whats_up_datas = response.data.whats_up
                    }
                    this.document_types = [
                        {
                            label: 'Select document type',
                            name: '',
                            disabled: true,
                        },
                        ...response.data.identity_document_type,
                    ]
                }
            })
            .catch((err) => console.error(err))
    },
    computed: {
        activeUser() {
            return this.$store.getters['auth/getAuthenticatedUser']
        },
        isUserApproved() {
            if (this.stateLoaded) {
                return this.activeUser.member_status === 'approved'
            }
        },
        isReviewPending() {
            if (this.stateLoaded) {
                return this.activeUser.member_status === 'pending_review'
            }
        },
        isUserActionPending() {
            if (this.stateLoaded) {
                return (
                    this.activeUser.member_status === 'pending_user_action' ||
                    !this.activeUser.is_phone_verified
                )
            }
        },
        checkIfFromBannedNation() {
            if (this.stateLoaded) {
                return (
                    this.isReviewPending &&
                    this.activeUser.country &&
                    this.activeUser.country.is_review_needed === 1
                )
            }
        },
        isHavingAnyExperience() {
            return (
                this.isHavingAnyPromotion ||
                this.isHavingAnyEvent ||
                this.isHavingAnyOffer
            )
        },
        isHavingAnyPromotion() {
            return (
                !this.isLoading &&
                'promotions' in this.groupData &&
                Object.keys(this.groupData.promotions).length > 0
            )
        },
        isHavingAnyEvent() {
            return (
                !this.isLoading &&
                'events' in this.groupData &&
                Object.keys(this.groupData.events).length > 0
            )
        },
        isHavingAnyOffer() {
            return (
                !this.isLoading &&
                'offers' in this.groupData &&
                Object.keys(this.groupData.offers).length > 0
            )
        },
    },
    watch: {
        stateLoaded(val) {
            // console.log('state loaded', val, this.activeUser.is_phone_verified, !this.activeUser.is_phone_verified);
            if (val) {
                this.member_status_based_text =
                    this.activeUser.member_status_based_text
                if (!this.activeUser.is_phone_verified) {
                    this.showModal()
                } else {
                    this.modalShow = false
                }
                if (
                    this.activeUser.member_status === 'pending_user_action' ||
                    !this.activeUser.is_phone_verified
                ) {
                    this.show_verification = true
                } else {
                    this.show_verification = false
                }
            }
        },
    },
    methods: {
        getButtonText(value) {
            if (value) {
                if (value.type == 'offers') {
                    let end_date = moment(value.end_date, 'YYYY-MM-DD')
                    let current_date = moment()
                    return (
                        'Valid for ' +
                        end_date.diff(current_date, 'days') +
                        (end_date.diff(current_date, 'days') > 1
                            ? ' days'
                            : ' day')
                    )
                }
                return moment(value.occurence_dates).format('dddd')
            }
            return ''
        },
        showModal() {
            this.$bvModal.show('vmodal')
        },
        showFileUploadModal() {
            this.$bvModal.show('idmodal')
        },
        afterUpload() {
            this.show_verification = false
            this.$store.dispatch('auth/attempt', this.$store.state.token)
            window.location.reload()
        },
        getData() {
            this.isLoading = true
            console.log(this.isUserApproved)
            let url = this.isUserApproved
                ? '/experiences'
                : '/experience-master-list'
            this.$http
                .get(url)
                .then((response) => {
                    if (response.status == 200) {
                        this.response = this.isUserApproved
                            ? response.data.response_data
                            : response.data.response_data.experiences
                        this.response.forEach((element) => {
                            this.groupData[element.type] = {}
                        })
                        this.response.forEach((element) => {
                            if (this.isUserApproved || element.is_public) {
                                if (element.type !== 'offers') {
                                    if (
                                        this.groupData[element.type][
                                            element.id
                                        ] &&
                                        moment(
                                            this.groupData[element.type][
                                                element.id
                                            ].occurence_dates
                                        ) > moment() &&
                                        moment(
                                            this.groupData[element.type][
                                                element.id
                                            ].occurence_dates
                                        ) > moment(element.occurence_dates)
                                    ) {
                                        this.groupData[element.type][
                                            element.id
                                        ] = element
                                    } else if (
                                        !this.groupData[element.type][
                                            element.id
                                        ]
                                    ) {
                                        this.groupData[element.type][
                                            element.id
                                        ] = element
                                    }
                                } else {
                                    this.groupData[element.type][element.id] =
                                        element
                                }
                            }
                        })
                        this.$store.dispatch(
                            'auth/store_experiences',
                            this.groupData
                        )
                        // this.groupData
                        this.isLoading = false
                    }
                })
                .catch((err) => {
                    console.error(err)
                })
        },

        gotonext(carouselname) {
            if (carouselname) {
                this.$refs[carouselname].goToPage(
                    this.$refs[carouselname].currentPage + 1
                )
            }
        },
        gotoprev(carouselname) {
            if (carouselname) {
                this.$refs[carouselname].goToPage(
                    this.$refs[carouselname].currentPage - 1
                )
            }
        },
        experienceDetails(val) {
            this.$router.push({
                name: `experiences_details`,
                params: {
                    type: val.type,
                    id: val.id,
                    event_data: val,
                },
            })
        },
    },
}
