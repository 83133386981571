var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"topbottom_padding"},[_c('div',{staticClass:"container"},[_c('h1',[_vm._v("Grand Casino Member Portal")]),(_vm.isUserActionPending || _vm.isReviewPending)?_c('div',{staticClass:"golden_button"},[_c('h2',[_vm._v(" "+_vm._s(_vm.member_status_based_text)+" ")]),(_vm.isUserActionPending)?_c('a',{staticClass:"btn_outline",on:{"click":_vm.showFileUploadModal}},[_vm._v("Verify")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"block"},[_c('div',{staticClass:"first_block"},[_c('div',{staticClass:"stage_block"},[_c('carousel',{ref:"whatsupcarousel",attrs:{"perPage":1,"paginationEnabled":false,"perPageCustom":[[1199, 1]],"autoplay":"","loop":"","autoplayTimeout":"5000"}},_vm._l((_vm.whats_up_datas),function(whats_up_data,key){return _c('slide',{key:key,staticClass:"whatsup-slide"},[_c('div',{staticClass:"card"},[_c('img',{staticClass:"card-img-top",attrs:{"src":whats_up_data
                                                ? whats_up_data.image_url
                                                : '',"alt":""}}),_c('div',{staticClass:"card-body d-flex",staticStyle:{"padding":"1.5rem 2rem"}},[_c('h3',{staticClass:"card-title"},[_vm._v(" "+_vm._s(whats_up_data ? whats_up_data.description : '')+" ")])])])])}),1)],1),_c('div',{staticClass:"buttons_block"},[_c('ul',[_c('li',[_c('a',{class:{
                                        btn: true,
                                        disabled: !_vm.isUserApproved,
                                    },attrs:{"href":"mailto:enquiries@grandcasino.co.nz?subject=Restaurant%20reservation%20request&bcc=mircot@grandcasino.co.nz&bcc=lydiap@grandcasino.co.nz","target":"_blank"}},[_vm._v("Reserve Table")])]),_c('li',[_c('a',{class:{
                                        btn: true,
                                        disabled: !_vm.isUserApproved,
                                    },attrs:{"href":"https://grandcasino.co.nz/grand-experiences/dining/","target":"_blank"}},[_vm._v("View Menus")])]),_c('li',[_c('router-link',{class:{
                                        btn: true,
                                        disabled: !_vm.isUserApproved,
                                    },attrs:{"to":"/hotel-bookings"}},[_vm._v("Hotel Booking")])],1)])])])]),(!_vm.checkIfFromBannedNation)?[(_vm.isHavingAnyExperience)?[(!_vm.isLoading && _vm.isHavingAnyPromotion)?_c('div',{staticClass:"block"},[_c('div',{staticClass:"heading_bar"},[_c('h2',[_vm._v("Promotions")]),_c('div',{staticClass:"buttons_block"},[_c('a',{on:{"click":function($event){return _vm.gotoprev('promotioncarousel')}}},[_c('img',{attrs:{"src":require("@/assets/images/arrow_left.png"),"alt":""}})]),_c('a',{on:{"click":function($event){return _vm.gotonext('promotioncarousel')}}},[_c('img',{attrs:{"src":require("@/assets/images/arrow_right.png"),"alt":""}})])])]),_c('div',{staticClass:"carousel_block"},[(!_vm.isLoading)?_c('carousel',{ref:"promotioncarousel",attrs:{"paginationEnabled":false,"perPageCustom":[
                                    [320, 1],
                                    [768, 1],
                                    [1024, 3],
                                    [1600, 3] ]}},_vm._l((_vm.groupData.promotions),function(value,index){return _c('slide',{key:index},[_c('div',{staticClass:"card m-4",on:{"click":function($event){return _vm.experienceDetails(value)}}},[_c('img',{staticClass:"card-img-top",attrs:{"src":value.img_url,"alt":""}}),_c('div',{staticClass:"\n                                                card-body\n                                                d-flex\n                                                min-height-80\n                                            "},[_c('h3',{staticClass:"card-title"},[_vm._v(" "+_vm._s(value.title)+" ")]),_c('a',{staticClass:"btn",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.getButtonText(value)))])])])])}),1):_vm._e()],1)]):_vm._e(),(!_vm.isLoading && _vm.isHavingAnyEvent)?_c('div',{staticClass:"block"},[_c('div',{staticClass:"heading_bar"},[_c('h2',[_vm._v("Events")]),_c('div',{staticClass:"buttons_block"},[_c('a',{on:{"click":function($event){return _vm.gotoprev('eventcarousel')}}},[_c('img',{attrs:{"src":require("@/assets/images/arrow_left.png"),"alt":""}})]),_c('a',{on:{"click":function($event){return _vm.gotonext('eventcarousel')}}},[_c('img',{attrs:{"src":require("@/assets/images/arrow_right.png"),"alt":""}})])])]),_c('div',{staticClass:"carousel_block"},[(!_vm.isLoading)?_c('carousel',{ref:"eventcarousel",attrs:{"paginationEnabled":false,"perPageCustom":[
                                    [320, 1],
                                    [768, 1],
                                    [1200, 3],
                                    [1600, 3] ]}},_vm._l((_vm.groupData.events),function(value,index){return _c('slide',{key:index},[_c('div',{staticClass:"card m-4",on:{"click":function($event){return _vm.experienceDetails(value)}}},[_c('img',{staticClass:"card-img-top",attrs:{"src":value.img_url,"alt":""}}),_c('div',{staticClass:"\n                                                card-body\n                                                d-flex\n                                                min-height-80\n                                            "},[_c('h3',{staticClass:"card-title"},[_vm._v(" "+_vm._s(value.title)+" "),_c('span',[_vm._v(_vm._s(value .event_attributes .price && value .event_attributes .price > 0 ? '$' + value .event_attributes .price : '')+" "+_vm._s(value.event_attributes.price && value.event_attributes.is_rsvp ? ' / ' : '')+" "+_vm._s(value .event_attributes .is_rsvp ? 'RSVP' : ''))])]),_c('a',{staticClass:"btn",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.getButtonText(value)))])])])])}),1):_vm._e()],1)]):_vm._e(),(!_vm.isLoading && _vm.isHavingAnyOffer)?_c('div',{staticClass:"block"},[_c('div',{staticClass:"heading_bar"},[_c('h2',[_vm._v("Offers")]),_c('div',{staticClass:"buttons_block"},[_c('a',{on:{"click":function($event){return _vm.gotoprev('COcarousel')}}},[_c('img',{attrs:{"src":require("@/assets/images/arrow_left.png"),"alt":""}})]),_c('a',{on:{"click":function($event){return _vm.gotonext('COcarousel')}}},[_c('img',{attrs:{"src":require("@/assets/images/arrow_right.png"),"alt":""}})])])]),_c('div',{staticClass:"carousel_block"},[(!_vm.isLoading)?_c('carousel',{ref:"COcarousel",attrs:{"paginationEnabled":false,"loop":"","perPageCustom":[
                                    [320, 1],
                                    [768, 1],
                                    [1200, 3],
                                    [1600, 3] ]}},_vm._l((_vm.groupData.offers),function(value,index){return _c('slide',{key:index},[_c('div',{staticClass:"card m-4",on:{"click":function($event){return _vm.experienceDetails(value)}}},[_c('img',{staticClass:"card-img-top",attrs:{"src":value.img_url,"alt":""}}),_c('div',{staticClass:"\n                                                card-body\n                                                d-flex\n                                                min-height-80\n                                            "},[_c('h3',{staticClass:"card-title"},[_vm._v(" "+_vm._s(value.title)+" ")]),_c('a',{staticClass:"btn",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.getButtonText(value)))])])])])}),1):_vm._e()],1)]):_vm._e()]:_vm._e(),(!_vm.isLoading && !_vm.isHavingAnyExperience)?[_c('div',{staticClass:"block no-experience-block"},[_vm._v(" Currently, there are no events/promotions/offers available but please check back soon ")])]:_vm._e()]:_vm._e()],2)]),_c('div'),_c('verificationModal',{attrs:{"modalShow":_vm.modalShow}}),_c('id-verification-modal',{attrs:{"document_types":_vm.document_types},on:{"uploaded":_vm.afterUpload}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }