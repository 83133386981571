import OtpInput from "@bachdgvn/vue-otp-input";
export default{
    components: {OtpInput},
    props:{
        modalShow: {
            default: false
        },
        number: {
            default: 0
        },
        closable: {
            default: false
        },
    },
    data(){
        return {
            displayModal: false,
            otp: '',
            disableResend: false,
            timer: 15,
            changePhNo: false,
            newPhoneNumber: '',
        }
    },
    created(){
        this.displayModal = this.modalShow;
    },
    methods:{
        openPhoneNumberChanged(boolvalue) {
            this.changePhNo = boolvalue;
        },
        hideModal() {
           this.displayModal = false;
        },
        verifyOTP() {
            this.$http.post('/verify-otp', 
            {
                'phone_number':  this.number ? this.number : this.activeUser.phone_number,
                'otp': this.otp,
                'mode': 'edit',
                'country_code': 64,
                'member_id': this.activeUser.member_id
            }).then((resp) => {
                if(resp.data.status){
                    this.$store.dispatch('auth/attempt', this.$store.state.token);
                    this.$emit('isVerified', true);
                    this.$emit('isModalClosedNow', true);
                    this.$helper.showMessage('Mobile Number Verified');
                    this.$bvModal.hide('vmodal');
                }
                else {
                    this.$emit('isVerified',false);
                }
            }).catch( err =>{
                console.log(err)
            })
           
        },
        sendOTP(){
            this.$http.post('/send-otp', 
                {
                    'phone_number':  this.number ? this.number : this.activeUser.phone_number,
                    'mode': 'edit',
                    'country_code': 64,
                    'member_id': this.activeUser.member_id,
                }).then( res=>{
                    console.log(res)
                }).catch( err =>{
                    console.log(err)
                })
        },
        sendOtpToNewNumber() {
            this.$http.post('/send-otp', 
                {
                    'phone_number':  this.newPhoneNumber,
                    'mode': 'edit',
                    'country_code': 64,
                    'member_id': this.activeUser.member_id,
                }).then( res=>{
                    console.log(res)
                    this.number = this.newPhoneNumber;
                    this.changePhNo = false;
                }).catch( err =>{
                    console.log(err)
                })
        },
        handleOnComplete(value) {
            this.otp = value;
        },
        handleOnChange(value){
            this.otp = value;
        },
        countDownTimer() {
            if(this.timer > 0) {
                setTimeout(() => {
                    this.timer -= 1
                    this.countDownTimer()
                }, 1000)
            } else {
                this.disableResend = false;
            }
        },
        resend(){
            this.disableResend = true;
            this.timer = 10;
            this.sendOTP();
            this.countDownTimer();
        }
    },
    computed: {
        activeUser() {
            return this.$store.getters['auth/getAuthenticatedUser'];
        },
        validate(){
            return this.otp.length!==4;
        },
        validatePhoneNumber(){
            return !isNaN(this.newPhoneNumber) && this.newPhoneNumber.length > 0;
        },
        maskedNo() {
            if(this.activeUser){
                let ph = this.number!==0 ? this.number : this.activeUser.phone_number;
                if(ph.startsWith("0")) {
                    ph = ph.substr(1, ph.length())
                }
                return ph.replace(/\d(?=\d{4})/g, "*");
            }
            return 0;
        }
    },
    watch: {
        number(val){
            console.log(val)
        },
        displayModal: function( val ){
            if(val) {
                this.sendOTP();
            }
        }
    },
}
